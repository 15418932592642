// enums
export enum SubscriptionTypeEnum {
    Pool = 'POOL',
    PoolRelated = 'POOL_RELATED',
    Single = 'SINGLE',
}

export enum AddressTypeEnum {
    Billing = 'BILLING',
    Postal = 'POSTAL',
    Primary = 'PRIMARY',
}

export enum CountryEnum {
    Ch = 'CH',
    De = 'DE',
    At = 'AT',
    Li = 'LI',
    Fi = 'FI',
}

export enum CurrencyEnum {
    Chf = 'CHF',
    Eur = 'EUR',
}

export enum CustomerSizeEnum {
    Large = 'LARGE',
    Medium = 'MEDIUM',
    Small = 'SMALL',
}

export enum CustomerTypeEnum {
    Chain = 'CHAIN',
    Contact = 'CONTACT',
    Distributor = 'DISTRIBUTOR',
    JointPractice = 'JOINT_PRACTICE',
    MedicalPractice = 'MEDICAL_PRACTICE',
}

export enum LicenseTypeEnum {
    Enterprise = 'ENTERPRISE',
    Free = 'FREE',
    Trial = 'TRIAL',
    Large = 'LARGE',
    Medium = 'MEDIUM',
    Small = 'SMALL',
    // Prepaid is only a type for filtering in the UI.
    // This type is not stored in the DB.
    Prepaid = 'PREPAID',
    Prepaid50 = 'PREPAID_50',
    Prepaid100 = 'PREPAID_100',
    Prepaid200 = 'PREPAID_200',
}

export enum PaymentIntervalEnum {
    Monthly = 'MONTHLY',
    OneTime = 'ONE_TIME',
    TwoYearly = 'TWO_YEARLY',
    Yearly = 'YEARLY',
}

export interface DateFilter {
    startDate: Date;
    endDate: Date;
    key: string;
}
