import * as React from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import {
    AddressTypeEnum,
    CountryEnum,
    CurrencyEnum,
    CustomerSizeEnum,
    CustomerTypeEnum,
    LicenseTypeEnum,
    PaymentIntervalEnum,
    SubscriptionTypeEnum,
} from '../../types';

export const customerSizeChoices = [
    { id: CustomerSizeEnum.Small, name: CustomerSizeEnum.Small },
    { id: CustomerSizeEnum.Medium, name: CustomerSizeEnum.Medium },
    { id: CustomerSizeEnum.Large, name: CustomerSizeEnum.Large },
];

export const SizeInput: React.FunctionComponent<SelectInputProps> = (props) => (
    <SelectInput
        source="size"
        choices={customerSizeChoices}
        resettable
        fullWidth
        parse={(v) => (v ? v : null)}
        {...props}
    />
);

export const customerTypeChoices = [
    {
        id: CustomerTypeEnum.Chain,
        name: CustomerTypeEnum.Chain,
    },
    {
        id: CustomerTypeEnum.JointPractice,
        name: CustomerTypeEnum.JointPractice,
    },
    {
        id: CustomerTypeEnum.MedicalPractice,
        name: CustomerTypeEnum.MedicalPractice,
    },
];

export const CustomerTypeInput: React.FunctionComponent<SelectInputProps> = (
    props
) => <SelectInput source="type" choices={customerTypeChoices} {...props} />;

export const addressTypes = [
    {
        id: AddressTypeEnum.Billing,
        name: AddressTypeEnum.Billing,
    },
    {
        id: AddressTypeEnum.Postal,
        name: AddressTypeEnum.Postal,
    },
    {
        id: AddressTypeEnum.Primary,
        name: AddressTypeEnum.Primary,
    },
];

export const AddressTypeInput: React.FunctionComponent<SelectInputProps> = (
    props
) => <SelectInput source="type" choices={addressTypes} {...props} />;

export const countries = [
    {
        id: CountryEnum.At,
        name: CountryEnum.At,
    },
    {
        id: CountryEnum.Ch,
        name: CountryEnum.Ch,
    },
    {
        id: CountryEnum.De,
        name: CountryEnum.De,
    },
    {
        id: CountryEnum.Fi,
        name: CountryEnum.Fi,
    },
    {
        id: CountryEnum.Li,
        name: CountryEnum.Li,
    },
];

export const CountryInput: React.FunctionComponent<SelectInputProps> = (
    props
) => <SelectInput source="country" choices={countries} {...props} />;

export const subscriptionTypes = [
    {
        id: SubscriptionTypeEnum.Single,
        name: SubscriptionTypeEnum.Single,
    },
    {
        id: SubscriptionTypeEnum.Pool,
        name: SubscriptionTypeEnum.Pool,
    },
    {
        id: SubscriptionTypeEnum.PoolRelated,
        name: SubscriptionTypeEnum.PoolRelated,
    },
];

export const SubscriptionTypeInput: React.FunctionComponent<
    SelectInputProps
> = (props) => (
    <SelectInput source="type" choices={subscriptionTypes} {...props} />
);

export const licenseTypes = [
    {
        id: LicenseTypeEnum.Enterprise,
        name: LicenseTypeEnum.Enterprise,
    },
    {
        id: LicenseTypeEnum.Trial,
        name: LicenseTypeEnum.Trial,
    },
    {
        id: LicenseTypeEnum.Free,
        name: LicenseTypeEnum.Free,
    },
    {
        id: LicenseTypeEnum.Small,
        name: LicenseTypeEnum.Small,
    },
    {
        id: LicenseTypeEnum.Medium,
        name: LicenseTypeEnum.Medium,
    },
    {
        id: LicenseTypeEnum.Large,
        name: LicenseTypeEnum.Large,
    },
    {
        id: LicenseTypeEnum.Prepaid,
        name: LicenseTypeEnum.Prepaid,
    },
    {
        id: LicenseTypeEnum.Prepaid50,
        name: LicenseTypeEnum.Prepaid50,
    },
    {
        id: LicenseTypeEnum.Prepaid100,
        name: LicenseTypeEnum.Prepaid100,
    },
    {
        id: LicenseTypeEnum.Prepaid200,
        name: LicenseTypeEnum.Prepaid200,
    },
];

export const LicenseTypeInput: React.FunctionComponent<SelectInputProps> = (
    props
) => (
    <SelectInput
        source="type"
        choices={licenseTypes.filter((l) => l.name !== 'TRIAL')}
        {...props}
    />
);

export const paymentIntervals = [
    {
        id: PaymentIntervalEnum.Monthly,
        name: PaymentIntervalEnum.Monthly,
    },
    {
        id: PaymentIntervalEnum.Yearly,
        name: PaymentIntervalEnum.Yearly,
    },
    {
        id: PaymentIntervalEnum.TwoYearly,
        name: PaymentIntervalEnum.TwoYearly,
    },
    {
        id: PaymentIntervalEnum.OneTime,
        name: PaymentIntervalEnum.OneTime,
    },
];
export const PaymentIntervalInput: React.FunctionComponent<SelectInputProps> = (
    props
) => <SelectInput source="type" choices={paymentIntervals} {...props} />;

export const CurrencyInput: React.FunctionComponent<SelectInputProps> = (
    props
) => (
    <SelectInput
        source="type"
        choices={[
            {
                id: CurrencyEnum.Chf,
                name: CurrencyEnum.Chf,
            },
            {
                id: CurrencyEnum.Eur,
                name: CurrencyEnum.Eur,
            },
        ]}
        {...props}
    />
);
