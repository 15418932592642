import User from '@mui/icons-material/AccountBox';
import Assessment from '@mui/icons-material/Assessment';
import { Box } from '@mui/material';
import { Popover, Typography } from '@mui/material';
import {
    addDays,
    differenceInCalendarDays,
    format,
    formatISO,
    isToday,
    subDays,
} from 'date-fns';
import { useDataProvider } from 'ra-core';
import { Loading } from 'ra-ui-materialui';
import { useEffect, useState } from 'react';
import { Button } from 'react-admin';
import { DateRangePicker } from 'react-date-range';
import { DateFilter } from '../../types';
import { DATE_FORMAT } from '../../util';
import LineUsageChart, { Period } from '../shared/LineUsageChart';
import {
    getPeriodsToCompare,
    getTotalsPerDay,
    TotalsPerDay,
} from '../shared/predictionsHelper';
import CardWithIcon from './CardWithIcon';
import staticDateRanges from './staticDateRanges';
import Subscriptions from './Subscriptions';
import { SubscriptionState } from './types';

const getDateFilter = (from: Date, to: Date): Record<string, any> => ({
    'date@_gte': formatISO(from, {
        representation: 'date',
    }),
    'date@_lte': formatISO(addDays(to, 1), {
        representation: 'date',
    }),
});

interface PredictionState {
    dateFilter: DateFilter[];
    today?: TotalsPerDay;
    totalsPerDay?: TotalsPerDay[];
    totalPredictions?: number;
    periodsToCompare?: Period[];
    result?: any;
}

const Dashboard: React.FC = () => {
    const dataProvider = useDataProvider();
    const [predictions, setPredictions] = useState<PredictionState>({
        dateFilter: [
            {
                startDate: subDays(new Date(), 14),
                endDate: new Date(),
                key: 'selection',
            },
        ],
    });
    const [expiringSubscriptions, setExpiringSubscriptions] = useState<
        SubscriptionState | undefined
    >(undefined);
    const [trialSubscriptions, setTrialSubscriptions] = useState<
        SubscriptionState | undefined
    >(undefined);

    useEffect(() => {
        const fetchPredictions = async () => {
            const result = await dataProvider.getList('nostic_caries_stats', {
                filter: getDateFilter(
                    predictions.dateFilter[0].startDate,
                    predictions.dateFilter[0].endDate
                ),
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 0, perPage: 0 },
            });

            const { data } = result;

            const numberOfDays = differenceInCalendarDays(
                predictions.dateFilter[0].endDate,
                predictions.dateFilter[0].startDate
            );

            const totalsPerDay = getTotalsPerDay(numberOfDays, data);
            const periodsToCompare = getPeriodsToCompare(totalsPerDay);
            setPredictions({
                ...predictions,
                totalPredictions: result.total,
                result: result.data,
                today: predictions.today
                    ? predictions.today
                    : totalsPerDay.find((t) => isToday(t.date)),
                totalsPerDay,
                periodsToCompare,
            });
        };

        fetchPredictions();
    }, [dataProvider, predictions.dateFilter]);

    useEffect(() => {
        const fetchExpiringSubscriptions = async () => {
            const result = await dataProvider.getList<any>('subscription', {
                filter: {
                    'license#type@_neq': 'TRIAL',
                    'is_valid@_eq': true,
                },
                sort: { field: 'remaining_days', order: 'ASC' },
                pagination: { page: 1, perPage: 10 },
            });
            setExpiringSubscriptions({
                total: result.total as number,
                subscriptions: result.data,
            });
        };
        fetchExpiringSubscriptions();
    }, [dataProvider]);

    useEffect(() => {
        const fetchTrialSubscriptions = async () => {
            const result = await dataProvider.getList<any>('subscription', {
                filter: {
                    'license#type@_eq': 'TRIAL',
                    'is_valid@_eq': true,
                },
                sort: { field: 'remaining_days', order: 'ASC' },
                pagination: { page: 1, perPage: 10 },
            });
            setTrialSubscriptions({
                total: result.total as number,
                subscriptions: result.data,
            });
        };
        fetchTrialSubscriptions();
    }, [dataProvider]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (!predictions.result || !expiringSubscriptions || !trialSubscriptions)
        return <Loading />;

    return (
        <Box
            sx={{
                display: 'flex',
                flexFlow: 'column nowrap',
                padding: '8px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    gap: '12px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        flexGrow: 1,
                        gap: '12px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexFlow: 'row nowrap',
                            gap: '12px',
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                            }}
                        >
                            <CardWithIcon
                                icon={Assessment}
                                title="Predictions Today"
                                subtitle={`${
                                    predictions.today?.totalPredictions || 0
                                }`}
                                to=""
                            />
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                            }}
                        >
                            <CardWithIcon
                                icon={User}
                                title="Active Users Today"
                                subtitle={`${
                                    predictions.today?.totalActiveUsers || 0
                                }`}
                                to={`/nostic_caries_user_stats?displayedFilters=%7B%7D&filter=%7B"date"%3A"${new Date().toISOString()}"%7D&order=DESC&page=1&perPage=25&sort=date`}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h4">Usage Statistics</Typography>
                        <Button
                            style={{ paddingLeft: '0' }}
                            size="large"
                            variant="text"
                            label={`${format(
                                predictions.dateFilter[0].startDate,
                                DATE_FORMAT
                            )} - ${format(
                                predictions.dateFilter[0].endDate,
                                DATE_FORMAT
                            )}`}
                            aria-describedby={id}
                            onClick={handleClick}
                        />
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            {/* eslint-disable no-undef */}
                            <DateRangePicker
                                onChange={(item) =>
                                    setPredictions({
                                        ...predictions,
                                        dateFilter: [item.selection] as any,
                                    })
                                }
                                staticRanges={staticDateRanges}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={predictions.dateFilter}
                                direction="vertical"
                            />
                            {/* eslint-enable no-undef */}
                        </Popover>
                    </Box>
                    <LineUsageChart
                        title="Total Predictions vs. Active Users"
                        data={predictions.totalsPerDay!}
                        dataKeys={[
                            {
                                name: 'totalPredictions',
                                label: 'Predictions',
                                color: '#887BB0',
                            },
                            {
                                name: 'totalActiveUsers',
                                label: 'Active Users',
                                color: '#59981A',
                            },
                        ]}
                        yScale="totalPredictions"
                    />
                    <LineUsageChart
                        title="Average Predictions per User"
                        data={predictions.totalsPerDay!}
                        dataKeys={[
                            {
                                name: 'avgPredictionsPerUser',
                                label: 'Avg. Predictions',
                                color: '#887BB0',
                            },
                        ]}
                        yScale="avgPredictionsPerUser"
                    />
                    <LineUsageChart
                        title="Weekly predictions compared"
                        periods={predictions.periodsToCompare!}
                        yScale="totalPredictions"
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexFlow: 'row nowrap',
                        flexGrow: 1,
                        gap: '12px',
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                        }}
                    >
                        <Subscriptions
                            title="Subscriptions"
                            data={expiringSubscriptions || []}
                            onAllClick={`/subscription?displayedFilters=%7B"license%23type%40_neq"%3Atrue%7D&filter=%7B"license%23type%40_neq"%3A"TRIAL"%7D&order=ASC&page=1&perPage=10&sort=remaining_days`}
                        />
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                        }}
                    >
                        <Subscriptions
                            title="Trial Subscriptions"
                            data={trialSubscriptions}
                            onAllClick={`/subscription?displayedFilters=%7B"license%23type%40_eq"%3Atrue%7D&filter=%7B"license%23type%40_eq"%3A"TRIAL"%7D&order=ASC&page=1&perPage=10&sort=remaining_days`}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
