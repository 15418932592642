import { format, getISODay, subDays } from 'date-fns';
import { chunk, find, take } from 'lodash';
import { RaRecord } from 'ra-core';
import { DATE_FORMAT, DATE_ISO_FORMAT } from '../../util';

const periodColors = ['#59981A', '#274472', '#FF8300', '#FAD02C'];
export interface TotalsPerDay {
    date: number;
    weekday: number;
    totalPredictions: number;
    totalActiveUsers: number;
    avgPredictionsPerUser: number;
}

export const getTotalsPerDay = (numberOfDays: number, data: RaRecord[]) => {
    const orderedDays = Array.from({ length: numberOfDays }, (_, i) =>
        subDays(new Date(), i)
    );

    return orderedDays.map((date) => {
        let totalPredictions = 0;
        let totalActiveUsers = 0;
        let avgPredictionsPerUser = 0;
        const dateWithPredictions: Record<string, any> | undefined = find(
            data,
            {
                date: format(date, DATE_ISO_FORMAT),
            }
        );

        if (dateWithPredictions) {
            totalPredictions = dateWithPredictions.predictions;
            totalActiveUsers = dateWithPredictions.active_users;
            avgPredictionsPerUser = dateWithPredictions.predictions_per_user;
        }
        return {
            date: date.getTime(),
            weekday: getISODay(date),
            totalPredictions,
            totalActiveUsers,
            avgPredictionsPerUser,
        };
    });
};

export const getPeriodsToCompare = (totalsPerDay: TotalsPerDay[]) => {
    const chunks = take(chunk(totalsPerDay, 7), 4);

    return chunks.map((c, i) => {
        const from = format(c[0].date, DATE_FORMAT);
        const to = format(c[c.length - 1].date, DATE_FORMAT);
        return {
            label: `${to} - ${from}`,
            color: periodColors[i],
            totalsPerDay: c,
        };
    });
};
